/** @jsx jsx */
import { jsx } from "theme-ui"
import { PageProps, useStaticQuery, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import HeroImage from "../components/hero-image"
import ExperienceSection from "../components/experience-section"
import ExperienceCard from "../components/experience-card"
import { MDXProvider } from "@mdx-js/react"
import Education from "../components/mdx/experience/education.mdx"
import Jobs from "../components/mdx/experience/jobs.mdx"
type ImageEdge = {
  node: ImageNode
}

type ImageNode = {
  name: string
  childImageSharp: {
    gatsbyImageData: any
  }
}

const HomePage = ({ location }: PageProps) => {
  const { allFile } = useStaticQuery(
    graphql`
      {
        allFile(filter: { relativePath: { glob: "experience/*" } }) {
          edges {
            node {
              name
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    `
  )
  const findImageNode = (name: string) => {
    const imageNodes = allFile.edges.map((imgEdge: ImageEdge) => imgEdge.node)
    return imageNodes.find((imgNode: ImageNode) => imgNode.name === name)
  }
  return (
    <Layout page="/">
      <SEO
        title="Mukul's Personal Website"
        path={location.pathname}
        pageType="other"
      />
      <HeroImage />
      <MDXProvider components={{ ExperienceCard, ExperienceSection }}>
        <Education isPrimaryBackground={true} findImageNode={findImageNode} />
        <Jobs isPrimaryBackground={false} findImageNode={findImageNode} />
      </MDXProvider>
    </Layout>
  )
}

export default HomePage
