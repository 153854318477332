/** @jsx jsx */
// @ts-ignore  (Grid import is fine)
import { jsx, Themed, Grid } from "theme-ui"
import { getImage, GatsbyImage } from "gatsby-plugin-image"

type ExperienceCardProps = {
  date: string
  location: string
  experience: string
  imageNode: {
    childImageSharp: {
      gatsbyImageData: any
    }
  }
  children: React.ReactNode
}

const ExperienceCard = ({
  date,
  location,
  experience,
  imageNode,
  children,
}: ExperienceCardProps) => (
  <Grid
    sx={{
      gridTemplateColumns: "1fr 2fr",
      gridTemplateRows: ["1fr 1fr", "1fr 1fr "],
      paddingY: "3em",
      paddingX: ["1em", "3em"],
      columnGap: ["1.5em", "3.5em"],
      rowGap: "0.5em",
      backgroundColor: `cardBackground`,
      borderRadius: "25px",
      boxShadow: "14px 10px 10px rgba(0, 0, 0, 0.2)",
      marginY: ["2em", "4em"],
      maxWidth: "750px",
      marginX: ["1em", "3em"],
    }}
  >
    <Themed.h3
      sx={{
        color: `secondary`,
        fontFamily: `heading`,
        fontWeight: `bold`,
        fontSize: 3,
        letterSpacing: `h3`,
        lineHeight: `heading`,
        marginY: "auto",
        textAlign: "center",
      }}
    >
      {date}
    </Themed.h3>
    <Themed.h3
      sx={{
        color: `primary`,
        fontFamily: `heading`,
        fontSize: 4,
        fontWeight: `normal`,
        lineHeight: `heading`,
        marginY: 0,
      }}
    >
      {" "}
      {location}{" "}
    </Themed.h3>
    <div />
    <Themed.h4
      sx={{
        color: `secondary`,
        fontFamily: `heading`,
        fontWeight: `bold`,
        fontSize: 3,
        letterSpacing: `h3`,
        lineHeight: `heading`,
        marginTop: "0.5em",
        marginBottom: 0,
      }}
    >
      {" "}
      {experience}{" "}
    </Themed.h4>
    <Themed.div
      sx={{
        marginY: "auto",
        padding: 0,
        maxWidth: "150px",
        width: ["100%", null],
        marginX: "auto",
        height: "auto",
      }}
    >
      <GatsbyImage image={getImage(imageNode)} imgStyle={{}} />
    </Themed.div>
    <Themed.div sx={{ marginLeft: 0, marginRight: "2em", marginY: "auto" }}>
      {children}
    </Themed.div>
  </Grid>
)

export default ExperienceCard
