/** @jsx jsx */
// @ts-ignore  (IconButton import is fine)
import { jsx, Themed, IconButton } from "theme-ui"
import { useStaticQuery, graphql } from "gatsby"

type SocialIconProps = {
  iconType: "linkedIn" | "twitter" | "github" | "youtube"
}
type SiteQueryType = {
  site: {
    siteMetadata: {
      social: {
        twitter: string
        github: string
        youtube: string
      }
    }
  }
}

const SocialIcon = ({ iconType }: SocialIconProps) => {
  const {
    site: {
      siteMetadata: {
        social: { twitter, github, youtube },
      },
    },
  }: SiteQueryType = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            social {
              twitter
              github
              youtube
            }
          }
        }
      }
    `
  )
  let href = ""
  let svg: JSX.Element = <span />
  switch (iconType) {
    case "linkedIn":
      href = "https://www.linkedin.com/in/mukul-rathi-17230014a/"
      svg = (
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 100 100"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="50" cy="50" r="50" fill="white" />
          <path
            d="M50 0C22.3875 0 0 22.3875 0 50C0 77.6125 22.3875 100 50 100C77.6125 100 100 77.6125 100 50C100 22.3875 77.6125 0 50 0ZM41.6667 66.6667H33.3333V41.6667H41.6667V66.6667ZM37.5 37.9542C34.9708 37.9542 32.9167 35.8875 32.9167 33.3333C32.9167 30.7833 34.9667 28.7125 37.5 28.7125C40.0333 28.7125 42.0833 30.7833 42.0833 33.3333C42.0833 35.8875 40.0292 37.9542 37.5 37.9542ZM70.8333 66.6667H62.5083V54.7458C62.5083 46.9083 54.1667 47.5708 54.1667 54.7458V66.6667H45.8333V41.6667H54.1667V46.2208C57.8 39.4875 70.8333 38.9875 70.8333 52.6708V66.6667Z"
            fill="#0077B5"
          />
        </svg>
      )

      break
    case "twitter":
      href = "https://www.twitter.com/" + twitter
      svg = (
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 100 100"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="50" cy="50" r="50" fill="white" />
          <path
            d="M50 0C22.3875 0 0 22.3875 0 50C0 77.6125 22.3875 100 50 100C77.6125 100 100 77.6125 100 50C100 22.3875 77.6125 0 50 0ZM75.275 40.1875C76.0375 57.0208 63.4833 75.7875 41.2583 75.7875C34.5 75.7875 28.2125 73.8042 22.9167 70.4083C29.2667 71.1583 35.6042 69.3917 40.6333 65.4542C35.4 65.3583 30.9792 61.8958 29.45 57.1417C31.3292 57.5 33.1792 57.3958 34.8583 56.9375C29.1042 55.7792 25.1292 50.5958 25.2583 45.05C26.875 45.9458 28.7167 46.4833 30.6792 46.5458C25.35 42.9833 23.8417 35.9458 26.975 30.5667C32.875 37.8083 41.6958 42.5708 51.6417 43.0708C49.8958 35.5875 55.575 28.375 63.3042 28.375C66.7417 28.375 69.8542 29.8292 72.0375 32.1542C74.7625 31.6208 77.3292 30.6208 79.6375 29.25C78.7417 32.0458 76.8458 34.3875 74.375 35.8708C76.7958 35.5792 79.1042 34.9375 81.2458 33.9833C79.6458 36.3917 77.6208 38.5 75.275 40.1875V40.1875Z"
            fill="#38A1F3"
          />
        </svg>
      )
      break
    case "github":
      href = "https://www.github.com/" + github
      svg = (
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 100 100"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="50" cy="50" r="50" fill="white" />
          <path
            d="M50 0C22.3917 0 0 22.3875 0 50C0 72.0917 14.325 90.8333 34.1958 97.4458C36.6917 97.9083 37.5 96.3583 37.5 95.0417V85.7333C23.5917 88.7583 20.6958 79.8333 20.6958 79.8333C18.4208 74.0542 15.1417 72.5167 15.1417 72.5167C10.6042 69.4125 15.4875 69.4792 15.4875 69.4792C20.5083 69.8292 23.15 74.6333 23.15 74.6333C27.6083 82.275 34.8458 80.0667 37.7 78.7875C38.1458 75.5583 39.4417 73.35 40.875 72.1042C29.7708 70.8333 18.0958 66.5458 18.0958 47.3917C18.0958 41.9292 20.05 37.4708 23.2458 33.9708C22.7292 32.7083 21.0167 27.6208 23.7333 20.7375C23.7333 20.7375 27.9333 19.3958 37.4875 25.8625C41.475 24.7542 45.75 24.2 50 24.1792C54.25 24.2 58.5292 24.7542 62.525 25.8625C72.0708 19.3958 76.2625 20.7375 76.2625 20.7375C78.9833 27.625 77.2708 32.7125 76.7542 33.9708C79.9625 37.4708 81.9 41.9333 81.9 47.3917C81.9 66.5958 70.2042 70.825 59.0708 72.0625C60.8625 73.6125 62.5 76.6542 62.5 81.3208V95.0417C62.5 96.3708 63.3 97.9333 65.8375 97.4417C85.6917 90.8208 100 72.0833 100 50C100 22.3875 77.6125 0 50 0Z"
            fill="#5A67D8"
          />
        </svg>
      )
      break
    case "youtube":
      href = youtube
      svg = (
        <svg
          width="100%"
          height="100%"
          viewBox="0 10 142 120"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0)">
            <path
              d="M139.877 37.126C138.231 31.0078 133.407 26.1851 127.29 24.5376C116.114 21.479 71.4086 21.479 71.4086 21.479C71.4086 21.479 26.7045 21.479 15.5283 24.421C9.52878 26.0674 4.58732 31.0089 2.9409 37.126C0 48.3012 0 71.4776 0 71.4776C0 71.4776 0 94.7705 2.9409 105.829C4.58841 111.946 9.4111 116.77 15.5293 118.418C26.8222 121.476 71.4097 121.476 71.4097 121.476C71.4097 121.476 116.114 121.476 127.29 118.534C133.408 116.888 138.231 112.064 139.879 105.947C142.819 94.7705 142.819 71.5953 142.819 71.5953C142.819 71.5953 142.937 48.3012 139.877 37.126Z"
              fill="#FF0000"
            />
            <path
              d="M57.1748 92.8888L94.3497 71.4776L57.1748 50.0664V92.8888Z"
              fill="white"
            />
          </g>
          <defs>
            <clipPath id="clip0">
              <rect width="142.82" height="142.82" fill="white" />
            </clipPath>
          </defs>
        </svg>
      )
      break
    // NOTE to self, social icons designed in Figma
  }

  return (
    <Themed.a
      href={href}
      title={iconType}
      sx={{
        marginX: "1em",
        transition: "0.3s ease",
        ":hover": {
          filter: "brightness(120%)",
          transform: " scale(1.1)",
        },
        ":active": {
          transform: " scale(0.9)",
        },
      }}
    >
      {svg}
    </Themed.a>
  )
}

export default SocialIcon
