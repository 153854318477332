/** @jsx jsx */
// @ts-ignore  (Flex, Label, Input import is fine)
import { jsx, Themed, Flex, Label, Input } from "theme-ui"
import React from "react"
import CallOutForm from "./callout-box"
import { useState } from "react"

type EmailSubscriptionFormProps = {
  heading?: string
  description?: string
}

const EmailSubscriptionForm = ({
  heading,
  description,
}: EmailSubscriptionFormProps) => {
  const defaultHeading =
    "I make content about my software engineering journey, curated in my newsletter!"

  const defaultDescription =
    "Tips from my time at Cambridge and Facebook, and early access to technical tutorials on machine learning, compilers and beyond."

  const [formState, setFormState] = useState({
    firstName: "",
    email: "",
    result: "await",
  })
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormState({
      ...formState,
      [`${e.target.name}`]: e.target.value,
    })
  }
  return ( <div/>
    // <Themed.div
    //   sx={{
    //     maxWidth: "700px",
    //     marginX: "0em",
    //     textAlign: "initial",
    //   }}
    // >
    //   <CallOutForm
    //     borderLeftColor={`subscribeLeftBar`}
    //     backgroundColor={`subscribeBackground`}
    //   >
    //     <Themed.h3
    //       sx={{
    //         color: `text`,
    //         marginTop: "0em",
    //         marginBottom: "0.5em",
    //         lineHeight: "1.4",
    //       }}
    //     >
    //       {heading ?? defaultHeading}
    //     </Themed.h3>
    //     <Themed.p sx={{ marginY: 0 }}>
    //       {description ?? defaultDescription}
    //     </Themed.p>
    //     <Themed.p sx={{ marginTop: "0.25em", marginBottom: "0.5em" }}>
    //       {" "}
    //       <a href="https://newsletter.mukulrathi.com">
    //         Check out previous issues!
    //       </a>
    //     </Themed.p>

    //     <form
    //       action="https://newsletter.mukulrathi.com/add_subscriber"
    //       method="post"
    //       target="_blank"
    //     >
    //       <Flex
    //         sx={{
    //           justifyContent: "flex-start",
    //           flexWrap: "wrap",
    //           marginX: "0em",
    //         }}
    //       >
    //         <Themed.div>
    //           <Label htmlFor="member_email">
    //             <Flex
    //               sx={{
    //                 flexDirection: "column",
    //                 justifyContent: "center",
    //                 marginRight: "1em",
    //               }}
    //             >
    //               <Themed.div
    //                 sx={{
    //                   marginBottom: "0.5em",
    //                   fontWeight: "bold",
    //                   textAlign: "initial",
    //                 }}
    //               >
    //                 Email Address
    //               </Themed.div>
    //               <Input
    //                 type="email"
    //                 onChange={handleChange}
    //                 name="member[email]"
    //                 id="member_email"
    //               />
    //             </Flex>
    //           </Label>
    //         </Themed.div>
    //         <Themed.div>
    //           <Input
    //             type="submit"
    //             value="Subscribe"
    //             name="member[subscribe]"
    //             id="member_submit"
    //             sx={{
    //               marginTop: "2em",
    //               backgroundColor: `subscribeButtonColor`,
    //               color: `subscribeButtonText`,
    //               paddingX: "2em",
    //               paddingY: "0.7em",
    //               border: 0,
    //               borderRadius: "10px",
    //             }}
    //           />
    //         </Themed.div>
    //       </Flex>
    //     </form>

    //     <Themed.div sx={{ marginTop: "2em " }}>
    //       By subscribing, you agree with Revue’s{" "}
    //       <a
    //         target="_blank"
    //         href="https://www.getrevue.co/terms"
    //         rel="noopener"
    //       >
    //         Terms of Service
    //       </a>{" "}
    //       and{" "}
    //       <a
    //         target="_blank"
    //         href="https://www.getrevue.co/privacy"
    //         rel="noopener"
    //       >
    //         Privacy Policy
    //       </a>
    //       .
    //     </Themed.div>
    //   </CallOutForm>
    // </Themed.div>
  )
}

export default EmailSubscriptionForm
