import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ExperienceSection = makeShortcode("ExperienceSection");
const ExperienceCard = makeShortcode("ExperienceCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <ExperienceSection heading="Experience" isPrimaryBackground={props.isPrimaryBackground} mdxType="ExperienceSection">
      <ExperienceCard date="Aug 2021-" location="Facebook" experience="Software Engineer" imageNode={props.findImageNode("facebook")} mdxType="ExperienceCard">
        <p>{`Working on E2EE in Messenger and Instagram.`}</p>
      </ExperienceCard>
      <ExperienceCard date="Jul - Sep 2020" location="Facebook" experience="Software Engineer Intern" imageNode={props.findImageNode("facebook")} mdxType="ExperienceCard">
        <p>{`Worked in the Data Portability Infra team, working on the Data Transfer Project.`}</p>
        <p>{`Completed assigned intern project and stretch goals by week 5 of 12-week internship. Completed a further 2 projects, the latter of which I independently scoped out myself.`}</p>
        <p>{`Used Java, Hack, React and Relay.`}</p>
      </ExperienceCard>
      <ExperienceCard date="Jun - Sep 2019" location="Facebook" experience="Software Engineer Intern" imageNode={props.findImageNode("facebook")} mdxType="ExperienceCard">
        <p>{`Worked in the Catalog Experience team. Used ReactJS and Hack as part of a full-stack business-facing project: implemented error logging infrastructure and surfaced errors in a diagnostics UI for pixel-based catalogs.`}</p>
      </ExperienceCard>
      <ExperienceCard date="Jul - Sep 2018" location="Jazz Networks" experience="Software Engineer Intern" imageNode={props.findImageNode("jazz-networks")} mdxType="ExperienceCard">
        <p>{`Worked in the Machine Learning team. Used a beta-VAE for unsupervised clustering of process file event data.`}</p>
      </ExperienceCard>
      <ExperienceCard date="Jul - Aug 2016" location="University of Nottingham" experience="Deep Learning Research Intern" imageNode={props.findImageNode("computer-vision-lab")} mdxType="ExperienceCard">
        <p>{`Interned in the Computer Vision Laboratory. Used Fully Convolutional Networks for semantic segmentation. Completed whilst still in school.`}</p>
      </ExperienceCard>
    </ExperienceSection>
    <p>{`)`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      