/** @jsx jsx */
// @ts-ignore  (Flex import is fine)
import { jsx, Themed, Flex } from "theme-ui"
import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import SocialIcon from "./social-icon"
import { useColorMode } from "theme-ui"
import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from "gatsby-background-image"
import Header from "./header"
import EmailSubscriptionForm from "./email-subscription-form"

type ImageEdge = {
  node: {
    name: string
    childImageSharp: {
      gatsbyImageData: any
    }
  }
}

const HeroImage = () => {
  const { allFile } = useStaticQuery(
    graphql`
      {
        allFile(filter: { name: { glob: "hero-image*" } }) {
          edges {
            node {
              name
              childImageSharp {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                )
              }
            }
          }
        }
      }
    `
  )
  const [colorMode, _] = useColorMode()
  const theme = colorMode === "dark" ? "dark" : "light"
  const imageNode = allFile.edges.filter(
    (edge: ImageEdge) => edge.node.name === "hero-image-" + theme
  )[0].node
  const image = getImage(imageNode)
  const bgImage = convertToBgImage(image)

  return (
    <BackgroundImage Tag="section" {...bgImage} preserveStackingContext>
      <Header page="/" />
      <Themed.div
        sx={{
          position: "absolute",
          right: 0,
          zIndex: -1,
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "#000",
          opacity: 0.4,
        }}
      />

      <Themed.div
        sx={{
          paddingY: ["3em", "7em "],
          paddingX: "1em",
          marginX: "auto",
          maxWidth: "850px",
          textAlign: "center",
        }}
      >
        <Themed.h1
          sx={{
            color: `heroHeading`,
            fontSize: 6,
            marginY: "0.4em",
            textTransform: "uppercase",
            marginX: "auto",
          }}
        >
          Mukul Rathi
        </Themed.h1>
        <Themed.h2
          sx={{
            color: `heroSubHeading`,
            marginX: ["1em", "auto"],
            marginTop: 0,
            marginBottom: ["1.5em", "2em"],
            fontSize: [3, 4],
          }}
        >
          MEng Cambridge CompSci graduate, now Software Engineer at Facebook.
        </Themed.h2>
        <Flex
          sx={{
            marginTop: ["2em", "5em "],
            height: "5em",
            justifyContent: "space-between",
            textAlign: "center",
          }}
        >
          <SocialIcon iconType="twitter" />
          <SocialIcon iconType="youtube" />
          <SocialIcon iconType="github" />
          <SocialIcon iconType="linkedIn" />
        </Flex>
        <Themed.div
          sx={{
            marginX: "auto",
            marginTop: "3em",
            display: "inline-block",
          }}
        >
          <EmailSubscriptionForm
            heading="I
          make content about my software engineering journey, curated in my newsletter!"
            description="Tips from my time at Cambridge and Facebook, and early access to technical tutorials on machine learning, compilers and beyond."
          />
        </Themed.div>
      </Themed.div>
    </BackgroundImage>
  )
}
export default HeroImage
