import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ExperienceSection = makeShortcode("ExperienceSection");
const ExperienceCard = makeShortcode("ExperienceCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <ExperienceSection heading="Education" isPrimaryBackground={props.isPrimaryBackground} mdxType="ExperienceSection">
      <ExperienceCard date="2020-2021" location="University of Cambridge" experience="MEng Computer Science" imageNode={props.findImageNode("uni-of-cam")} mdxType="ExperienceCard">
        <p>{`Awarded `}<em parentName="p">{`Honours with Distinction`}</em>{`.`}</p>
        <p><strong parentName="p">{`Dissertation:`}</strong>{` “Machine Unlearning” scored 91/100. No ranks awarded due to COVID.`}</p>
      </ExperienceCard>
      <ExperienceCard date="2017-2020" location="University of Cambridge" experience="B.A. (Hons) Computer Science" imageNode={props.findImageNode("uni-of-cam")} mdxType="ExperienceCard">
        <p>{`Awarded a `}<em parentName="p">{`First Class degree.`}</em></p>
        <p><strong parentName="p">{`Third Year Results:`}</strong>{` Top Dissertation - ‘Types for Data-Race Freedom’. No overall ranks awarded due to COVID, received a high First Class.`}</p>
        <p><strong parentName="p">{`Second Year Results:`}</strong>{` Ranked 3rd out of 102.`}</p>
        <p><strong parentName="p">{`First Year Results:`}</strong>{` Ranked 2nd out of 101, 0.14 marks out of 400 behind the top-ranked student.`}</p>
      </ExperienceCard>
      <ExperienceCard date="2010-2017" location="Nottingham High School" experience="GCSEs and A Levels" imageNode={props.findImageNode("notts-high")} mdxType="ExperienceCard">
        <p><strong parentName="p">{`A Levels:`}</strong>{` 4 A`}{`*`}{`s in Maths, Further Maths, Physics and Chemistry`}</p>
        <p><strong parentName="p">{`GCSEs:`}</strong>{` 11 A`}{`*`}{`s and A (top grade) in FSMQ Additional Maths”`}</p>
      </ExperienceCard>
    </ExperienceSection>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      