/** @jsx jsx */
// @ts-ignore  (Flex import is fine)
import { jsx, Themed, Flex } from "theme-ui"

type ExperienceSectionProps = {
  heading: String
  isPrimaryBackground: boolean
  children: React.ReactNode
}

const ExperienceSection = ({
  heading,
  isPrimaryBackground,
  children,
}: ExperienceSectionProps) => {
  return (
    <section>
      <Flex
        sx={{
          marginX: "auto",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          backgroundColor: isPrimaryBackground
            ? `background`
            : `secondaryBackground`,
          paddingY: "1em",
        }}
      >
        <Themed.h2
          sx={{
            marginTop: "2.5em",
            marginBottom: 0,
            fontSize: 5,
            textTransform: "uppercase",
          }}
        >
          {heading}
        </Themed.h2>
        {children}
      </Flex>
    </section>
  )
}

export default ExperienceSection
